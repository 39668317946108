@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100&display=swap');

:root {
    --main-ease: 0.25s ease;

    --base-white: rgb(255, 255, 255);
    --base-black: rgb(0, 0, 0);

    --base-text: var(--base-white);
    --base-disabled: rgb(175, 175, 175);
    --base-error: 255 105 105;
    --base-success: 55 169 133;
    --base-warning: 245 158 11;

    --error: rgb(var(--base-error));
    --error-light: rgb(var(--base-error) / 80%);
    --success: rgb(var(--base-success));
    --success-light: rgb(var(--base-success) / 80%);
    --warning: rgb(var(--base-warning));
    --warning-light: rgb(var(--base-warning) / 80%);

    --base-primary-main: 44 126 220;
    --base-layout-background: 216 232 255;

    --primary-main: rgb(var(--base-primary-main));
    --primary-transparent: rgb(var(--base-primary-main) / 50%);
    --primary-less-transparent: rgb(var(--base-primary-main) / 75%);
    --primary-light: rgb(50, 140, 248);
    --primary-dark: rgb(45, 122, 257);
    --secondary-main: rgb(255, 152, 26);
    --secondary-light: rgb(199, 163, 83);
    --secondary-dark: rgb(156, 124, 53);
    --black-main: rgb(20, 20, 20);
    --black-light: rgb(32, 32, 32);
    --black-lighter: rgb(56, 56, 56);
    --layout-background: rgb(var(--base-layout-background) / 4%);
    --layout-background-dark: rgb(var(--base-layout-background) / 12%);
}

body {
    font-family: 'Sarabun', 'sans-serif';
}

html {
    font-size: 12px;
}

*::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

*::-webkit-scrollbar-track {
    background-color: var(--layout-background-dark);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--primary-main);
    border-radius: 9999px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* --- CLASSES --- */
.budgetary-border-selector .budgetary-border-top {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

.budgetary-border-selector .budgetary-border-bottom {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.budgetary-border-selector .budgetary-parent-selector:last-child .budgetary-child-selector:last-child {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.user-select-none,
.no-user-select-recursive,
.no-user-select-recursive * {
    user-select: none !important;
}

.list-line {
    width: 0.5rem;
    height: 1px;
    transform: translateY(0.6rem);
    background-color: #fff;
    margin-right: 0.5rem;
    flex-shrink: 0;
}

.list-circle {
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 50%;
    transform: translateY(0.55rem);
    background-color: #fff;
    margin-right: 0.5rem;
    margin-left: 1rem;
    flex-shrink: 0;
}

h1 {
    font-size: 2.4rem;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    line-height: 1.1;
}

h2 {
    font-size: 2rem;
    font-weight: 500;
    color: #fff;
    line-height: 1.2;
}

h3 {
    font-size: 1.4rem;
    font-weight: 400;
    color: #fff;
    line-height: 1.2;
}

h4 {
    font-size: 1.2rem;
    font-weight: 700;
    color: #fff;
    line-height: 1.2;
}

h5 {
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    line-height: 1.2;
}

h6 {
    font-size: 1rem;
    font-weight: 300;
    color: #fff;
    line-height: 1.2;
}

p {
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 400;
    color: #fff;
}

.page-container {
    padding: 3rem;
}

@media only screen and (max-width: 750px) {
    .page-container {
        padding: 2rem 1rem;
    }
}

/* --- SEARCH INPUT --- */
.search-input-container {
    display: flex;
    align-items: center;
    position: relative;
}

.search-input-container .search-icon {
    width: 1.5rem;
    height: 1.5rem;
    color: #fff;
}

.search-input-container .search-icon-big {
    width: 2.35rem;
    height: 2.35rem;
    color: #fff;
}

.search-input {
    width: 170px;
    font-size: 1rem;
    border: 1px solid;
    transition:
        border-color var(--main-ease),
        width var(--main-ease);
}

.search-input::placeholder {
    color: rgba(255, 255, 255, 0.75);
    font-style: italic;
    font-weight: 300;
}

.search-input-container:hover .search-input,
.search-input:focus {
    width: 175px;
    border-color: var(--primary-light);
}

.search-input-container:hover .search-input.light,
.search-input.light:focus {
    border-color: #fff !important;
}

/* --- SIDEBAR --- */
.main-sidebar {
    position: relative;
    min-height: 100%;
    width: calc(5rem + 10px);
    flex-shrink: 0;
    align-self: stretch;
    transition: width var(--main-ease);
    z-index: 101;
}

.main-sidebar .inner-sidebar {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    width: 5rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--primary-dark);
    transition: width 0.35s ease;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    overflow: hidden;
}

.sidebar-content {
    opacity: 1;
}

.nav-list {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
}

.nav-list::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.nav-list::-webkit-scrollbar-track {
    background-color: transparent;
}

.nav-list::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.65);
    border-radius: 9999px;
}

.li-icon-width {
    flex-shrink: 0 !important;
    width: 2.5rem !important;
    height: 2rem !important;
    font-size: 1.85rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 750px) {
    .main-sidebar {
        width: 100%;
        min-height: calc(5rem - 10px);
        position: fixed;
        transition: min-height ease 0.2s;
    }

    .main-sidebar .inner-sidebar {
        width: 100%;
        overflow: visible;
        top: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;
    }

    .sidebar-content {
        opacity: 0;
    }

    .delay-sidebar-content {
        animation: delay-sidebar-content 0.4s forwards;
    }

    @keyframes delay-sidebar-content {
        to {
            opacity: 1;
        }
    }
}

@media only screen and (max-width: 750px) {
    .main-sidebar-open {
        min-height: 100vh;
    }
}

@media (hover: hover) and (min-width: 750px) {
    .main-sidebar:hover {
        width: 100px;
    }
}

@media (hover: hover) and (min-width: 750px) {
    .main-sidebar:hover .inner-sidebar {
        width: 300px;
    }
}

/* --- RIGHT SIDE --- */
.main-content-area {
    position: relative;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    flex: 1;
    z-index: 100;
    overflow-y: scroll;
}

@media only screen and (max-width: 750px) {
    .main-content-area {
        margin-top: calc(5rem - 10px);
    }
}

/* --- ANIMATION ON HOVER --- */
@media (hover: hover) and (min-width: 750px) {
    .appear-on-expand {
        transform: translateX(-20px);
        opacity: 0;
        transition:
            opacity 0.35s ease,
            transform 0.35s ease;
    }

    .move-on-expand {
        transform: translateX(-27.5px);
        transition: transform 0.35s ease;
    }

    .move-child-on-expand {
        transform: translateX(-50px);
        transition: transform 0.35s ease;
    }

    .main-sidebar:hover .appear-on-expand {
        transform: translateX(0);
        opacity: 1;
    }

    .main-sidebar:hover .move-on-expand {
        transform: translateX(0);
    }

    .main-sidebar:hover .move-child-on-expand {
        transform: translateX(0);
    }
}

/* --- NAVIGATION LINK TAB --- */
.nav-link-tab:not(.active-nav-link-tab):not(.disabled-nav-link-tab) div:hover p,
.nav-link-tab:not(.active-nav-link-tab):not(.disabled-nav-link-tab) div:hover p span svg path {
    color: rgba(50, 140, 248, 1);
    fill: rgba(50, 140, 248, 1);
}

.active-nav-link-tab div {
    background-color: rgba(50, 140, 248, 1);
}

.active-nav-link-tab div p {
    color: var(--base-text);
}

.disabled-nav-link-tab {
    pointer-events: none;
}

/* --- CONTENT SECTION --- */
.content-section {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 750px) {
    .content-section {
        min-height: calc(100vh - 5rem + 10px);
    }
}

/* --- DROPDOWN --- */
.dropdown-selected {
    position: relative;
    width: 100%;
    max-width: 512px;
    padding-right: 0.5rem;
    padding-left: calc(1rem + 1px);
    height: 2.75rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color var(--main-ease);
    user-select: none;
    color: #fff;
    z-index: 50;
}

.dropdown-selected.is-icon {
    /* width: 2.75rem; */

    padding: 10px !important;
    justify-content: center;
}

.dropdown-selected.default {
    background-color: rgba(29, 140, 248, 0.16);
}

.dropdown-selected.disabled {
    background-color: rgba(196, 196, 196, 0.6);
}

.dropdown-selected.default:hover {
    background-color: rgba(29, 140, 248, 0.6);
}

.dropdown-selected.green {
    background-color: #37a985;
}

.dropdown-selected.green:hover {
    background-color: #3cb991;
}

.dropdown-selected.black {
    background-color: rgba(255, 255, 255, 0.05);
}

.dropdown-selected.black:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.dropdown-selected.hasValue {
    background-color: #328cf8 !important;
}

.dropdown-selected.clicked {
    border-radius: 5px 5px 0 0;
}

.dropdown-selected p {
    width: calc(100% - 15px);
    overflow: hidden;
}

.dropdown-selected > svg {
    flex-shrink: 0;
    margin-left: 0.5rem;
    font-size: 1.25rem;
}

.dropdown-selected.is-icon > svg {
    margin-left: 0 !important;
}

.dropdown-options {
    position: absolute;
    top: 100%;
    min-width: calc(100% + 5px);
    border-radius: 0 2.5px 2.5px 2.5px;
    background-color: var(--primary-light);
    z-index: 55;
}

.dropdown-options.green {
    background-color: #37a985;
}

.dropdown-options ul {
    max-height: 20rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

.dropdown-options ul::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.dropdown-options ul::-webkit-scrollbar-track {
    background: transparent !important;
}

/* Handle */
.dropdown-options ul::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.75) !important;
    border-radius: 999px !important;
}

/* Handle on hover */
.dropdown-options ul::-webkit-scrollbar-thumb:hover {
    background: transparent !important;
}

.dropdown-options ul li {
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    transition: background-color var(--main-ease);
    white-space: nowrap;
    user-select: none;
}

.dropdown-options .search-li {
    padding: 0.5rem;
    border-top-right-radius: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dropdown-options ul li > p {
    user-select: none;
    pointer-events: none;
}

.dropdown-options ul li:hover,
.dropdown-options ul li.active {
    background-color: rgba(255, 255, 255, 0.15);
}

/* .dropdown-z-index {
    z-index: 999;
} */

/* --- DATE PICKER --- */
.hidden-default-date-picker {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    top: 0;
    left: 50%;
}

/* --- TOGGLE --- */
.toggle-component {
    position: relative;
    border-radius: 999px;
    background-color: rgb(20, 20, 20);
    width: 2.5rem;
    height: 1.5rem;
    cursor: pointer;
    user-select: none;
    transition: background-color var(--main-ease);
    overflow: hidden;
}

.toggle-component > div {
    position: absolute;
    left: 0.25rem;
    top: 50%;
    transform: translate(0, -50%);
    width: calc(1rem);
    height: calc(1rem);
    border-radius: 50%;
    transition:
        transform var(--main-ease),
        background-color var(--main-ease);
    background-color: var(--primary-light);
}

.toggle-component.checked {
    background-color: var(--primary-light);
}

.toggle-component.checked > div {
    transform: translate(1rem, -50%);
    background-color: #fff;
}

.toggle-component.disabled {
    cursor: not-allowed;
}

/* --- INPUT GROUP --- */
.input-group {
    border-left: 1px solid rgba(255, 255, 255, 0.05);
    transition: border-color var(--main-ease);
}

.input-group:hover {
    border-color: rgba(255, 255, 255, 0.25);
}

/* --- ACTION BUTTON --- */
.ACTION-BUTTON {
    transition: filter 0.2s ease;
}
.ACTION-BUTTON:hover {
    filter: brightness(1.2);
}

.ACTION-BUTTON *,
.ACTION-BUTTON svg {
    font-size: 1.25rem !important;
    color: #fff !important;
}

/* Mui  override */
.MuiPickersClock-clock {
    background-color: rgba(44, 126, 220, 0.5) !important;
}

body > iframe {
    display: none !important;
    pointer-events: none !important;
    visibility: hidden !important;
}

.pipeline-container::webkit-scrollbar {
    width: 0 !important;
    display: none;
    overflow-x: scroll;
}

.no-child-pointer-events * {
    pointer-events: none;
    user-select: none;
}

/* .Example header {
    background-color: #323639;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    padding: 20px;
    color: white;
}

.Example header h1 {
    font-size: inherit;
    margin: 0;
} */

.Example__container {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.Example__container__document .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.Example__container__document .react-pdf__Page {
    width: 100%;
}

.Example__container__document .react-pdf__Page canvas {
    width: 100% !important;
    height: 100% !important;
    border-radius: 15px;
}

.Example__container__document .react-pdf__message {
    padding: 20px;
    color: white;
}

@media only screen and (max-width: 600px) {
    .Example__container__document .react-pdf__Page {
        width: 300px;
    }
}

@media only screen and (max-width: 400px) {
    .Example__container__document .react-pdf__Page {
        width: 250px;
    }
}

/* Gantt wrapper class */

.gantt-wrapper::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 1px solid rgb(192, 192, 192);
}

/* This hide arrows from an input by number type */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
.wrapperClassName .rdw-option-wrapper {
    border: 1px solid #f1f1f1;
    padding: 5px;
    min-width: 30px;
    height: 30px;
    border-radius: 2px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}
